import React, { useState, useEffect } from 'react';
import styles from './SubscriptionAddOns.css';
import PropTypes from 'prop-types';

const SubscriptionAddOns = ({ subscriptionAddsOnData, setSubscriptionAddsOn, selectedAddOn, selectedPlan}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Effect to handle default selection based on selectedAddOn
  useEffect(() => {
    if (selectedAddOn) {
      let newSelectedOptions = [...selectedOptions]; // Preserve existing selections

      if (selectedAddOn.byoSecondaryGps && selectedPlan === "just-mates") {
        newSelectedOptions = Array.from(new Set(['just-mates-secondary']));
        newSelectedOptions = newSelectedOptions.filter(option =>
          !option.startsWith('good-mates-') && !option.startsWith('best-mates-')
        );
      }

      if (selectedAddOn.byoSecondaryGps && selectedPlan === "good-mates") {
        newSelectedOptions = Array.from(new Set([...newSelectedOptions, 'good-mates-secondary']));
        newSelectedOptions = newSelectedOptions.filter(option =>
          !option.startsWith('just-mates-') && !option.startsWith('best-mates-')
        );
      }

      if (selectedAddOn.byoSecondaryGps && selectedPlan === "best-mates") {
        newSelectedOptions = Array.from(new Set([...newSelectedOptions, 'best-mates-secondary']));
        newSelectedOptions = newSelectedOptions.filter(option =>
          !option.startsWith('good-mates-') && !option.startsWith('just-mates-')
        );
      }

      if (selectedAddOn.byoLockbox && selectedPlan === "good-mates") {
        newSelectedOptions = Array.from(new Set([...newSelectedOptions, 'good-mates-lockbox']));
      }

      setSelectedOptions(newSelectedOptions);
      setSubscriptionAddsOn(newSelectedOptions); // Update props when new options are set
    }
  }, [selectedAddOn, selectedPlan]);

  // Log selectedOptions to track updates
  useEffect(() => {
    setSubscriptionAddsOn(selectedOptions)
  }, [selectedOptions]);

  // Handle selection and deselection of add-ons
  const handleOptionClick = (optionId, isDisable) => {
    if (isDisable) return;

    setSelectedOptions((prevSelectedOptions) => {
      const isSelected = prevSelectedOptions.includes(optionId);
      const newSelectedOptions = isSelected
        ? prevSelectedOptions.filter(id => id !== optionId) // Deselect option
        : [...prevSelectedOptions, optionId]; // Select option

      setSubscriptionAddsOn([
        ...subscriptionAddsOnData
      ]); // Update props with new selected options
      return newSelectedOptions;
    });
  };

  return (
    <div className={styles.subscriptionAddons}>
      <h3>Select add-ons for your subscription</h3>
      <div className={styles.addonsOptions}>
        {subscriptionAddsOnData &&
          subscriptionAddsOnData.map(({ id, badge, isDisable, content }) => (
            <div
              className={`${styles.addonOption} ${selectedOptions.includes(id) ? styles.selected : ''}`}
              onClick={() => handleOptionClick(id, isDisable)}
              key={id}
            >
              <span>{content}</span>
              <div className={styles.radioCircle}>
                {selectedOptions.includes(id) && <div className={styles.radioSelected} />}
              </div>
              {badge && (
                <div className={styles.badgeContainer}>
                  <p className={styles.badgeContent}>{badge}</p>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SubscriptionAddOns;

SubscriptionAddOns.propTypes = {
  subscriptionAddsOnData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      badge: PropTypes.string, // Adjusted badge type to be string
      isDisable: PropTypes.bool,
    })
  ).isRequired,
  setSubscriptionAddsOn: PropTypes.func.isRequired,
};
