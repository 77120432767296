import React from "react";
import css from "../index.css";
import lockSvg from "../../../../assets/landingPageNew/sub-lock.svg";
import gpsSvg from "../../../../assets/landingPageNew/sub-gps.svg";
import mileageSvg from "../../../../assets/landingPageNew/sub-mileage.svg";
import PropTypes from 'prop-types';

const SubscriptionsCardSection = ({
                                    subscriptionPlan,
                                    isSelected,
                                    onSelect,
                                    addsOn
                                  }) => {
  const {label, type, gpsText, mileageText, lockText, isMonth, lockTextForLockbox} = subscriptionPlan;

  return (
    <div
      className={`${css.matesWrapper} ${isSelected ? css.selected : ''}`}
      onClick={onSelect}
    >
      <h4>{label}</h4>
      <ul>
        <ListItem src={gpsSvg} alt="gps" text={gpsText}/>
        <ListItem src={mileageSvg} alt="mileage" text={mileageText}/>
        <ListItem src={lockSvg} alt="lock" text={addsOn && addsOn.includes('good-mates-lockbox') ? (lockTextForLockbox || "") : lockText}/>
      </ul>
      <span>{type} {isMonth && <span>/month</span>}</span>
    </div>
  );
};

const ListItem = ({src, alt, text}) => (
  <li>
    <img src={src} alt={alt}/>
    {text}
  </li>
);


SubscriptionsCardSection.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  gpsText: PropTypes.string.isRequired,
  mileageText: PropTypes.string.isRequired,
  lockText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ListItem.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SubscriptionsCardSection;
