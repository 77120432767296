import React from 'react';
import {
  AboutPage,
  AccountSettingDriverVerificationPage,
  AddOnsPage,
  AddressDetailsPage,
  AuthenticationPage,
  BecomeAGuestPage,
  BecomeAHostPage,
  BeginnerResourcesPage,
  BLandingPage,
  CalendlyPage,
  Career,
  CheckEligibilityPage,
  CheckoutPage,
  ConfirmSignUpPage,
  ContactDetailsPage,
  ContactUsPage,
  CovidCheckoutPage,
  CreditsDetailsPage,
  DataDeletionInstruction,
  EditListingPage,
  EditTripPage,
  EmailVerificationPage,
  GenerateUserPage,
  GrabHostYourCarLandingPage,
  GrabRentACarLandingPage,
  GuestLandingPage,
  HostPromoLandingPage,
  HostPromoLandingPartners,
  HowItWorkPage,
  InboxPage,
  InstantBookingLandingPage,
  LandingPage,
  LandingPageFlex,
  LandingPageG,
  LandingPageGo,
  LandingPageByod,
  LandingPageGoHost,
  LandingPageH,
  LandingPageNew,
  ListingPage,
  LongTermRentalListingPage,
  LongTermRentalSearch,
  ManageListingsPage,
  MonthlyStatementPage,
  NewGuestLandingPage,
  NextLevelPage,
  NotFoundPage,
  NRICSettingPage,
  PartnerBrandingPage,
  PartnerBrandingPageVersion2,
  PartnerBrandingPageVersion3,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PaymentMethodsPage,
  PayoutPrefPage,
  PersonalDetailsPage,
  PoliciesPage,
  PrivacyPolicyPage,
  ProfilePage,
  ProfileSettingsPage,
  ReferAHostPage,
  RefundableDepositPage,
  RentalAgreementPage,
  SearchPage,
  SeoLocationLandingPage,
  SignUpPromoLandingPage,
  StripePayoutPage,
  StyleguidePage,
  SyncCalenderPage,
  TermsOfServicePage,
  TransactionPage,
  TripDetailsPage,
  TrustAndSafetyPage,
  VaccineCredits,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PersonalDetailsPage',
  'SyncCalenderPage',
  'AddressDetailsPage',
  'PasswordChangePage',
  'NRICSettingPage',
  'AccountSettingDriverVerificationPage',
  'PayoutPrefPage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPageNew {...props} />,
      loadData: LandingPageNew.loadData,
    },
    {
      path: '/rent-with-drive-mate',
      name: 'GuestLandingPage',
      component: props => <GuestLandingPage {...props} />,
      loadData: LandingPageNew.loadData,
    },
    {
      path: '/subscription',
      name: 'LandingPageFlex',
      component: props => <LandingPageFlex {...props} version="v1" />,
      loadData: LandingPageFlex.loadData,
    },
    {
      path: '/subscription-lead',
      name: 'LandingPageFlexLead',
      component: props => <LandingPageFlex {...props} version="v2" />,
      loadData: LandingPageFlex.loadData,
    },
    {
      path: '/subscription/cars',
      name: 'LongTermRentalSearch',
      component: props => <LongTermRentalSearch {...props} />,
      loadData: LongTermRentalSearch.loadData,
    },
    {
      path: '/subscription/cars/:slug/:id',
      name: 'LongTermRentalListingPage',
      component: props => <LongTermRentalListingPage {...props} />,
      loadData: LongTermRentalListingPage.loadData,
    },
    {
      path: '/rent-cars-with-drive-mate',
      name: 'NewGuestLandingPage',
      component: props => <NewGuestLandingPage {...props} />,
      loadData: NewGuestLandingPage.loadData,
    },
    {
      path: '/landing-old',
      name: 'LandingPageOld',
      component: props => <LandingPage {...props} />,
      loadData: LandingPage.loadData,
    },
    {
      path: '/b-landing',
      name: 'BLandingPage',
      component: props => <BLandingPage {...props} />,
      loadData: BLandingPage.loadData,
    },
    {
      path: '/share',
      name: 'LandingPageH',
      component: props => <LandingPageH {...props} />,
    },
    {
      path: '/rent',
      name: 'LandingPageG',
      component: props => <LandingPageG {...props} />,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: () => <AboutPage />,
    },
    {
      path: '/calendly',
      name: 'CalendlyPage',
      component: () => <CalendlyPage />,
    },
    {
      path: '/how-to-rent-a-car',
      name: 'BecomeAGuestPage',
      component: BecomeAGuestPage,
    },
    {
      path: '/become-a-host',
      name: 'BecomeAHostPage',
      component: BecomeAHostPage,
    },
    {
      path: '/trust-and-safety',
      name: 'TrustAndSafetyPage',
      component: TrustAndSafetyPage,
    },
    {
      path: '/contact-us',
      name: 'ContactUsPage',
      component: ContactUsPage,
    },
    {
      path: '/how-drivemate-works',
      name: 'HowItWorkPage',
      component: HowItWorkPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} tab="filters" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/go/:slug/:id',
      name: 'ListingPageGo',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    // {
    //   path: '/long-term/:slug/:id/:transactionId/checkout-long-term',
    //   name: 'CheckoutLongTermPage',
    //   auth: true,
    //   component: props => <CheckoutLongTermPage {...props} />,
    //   setInitialValues: CheckoutLongTermPage.setInitialValues,
    // },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/confirm-signup',
      name: 'ConfirmSignUpPage',
      auth: true,
      component: props => <ConfirmSignUpPage {...props} tab="confirm-signup" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    // {
    //   path: '/inbox/long-term-trips',
    //   name: 'InboxLongTermBasePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: () => <NamedRedirect name="InboxLongTermPage" params={{ tab: 'sales' }} />,
    // },
    {
      path: '/referral',
      name: 'ReferralPage',
      component: () => <ReferAHostPage name="ReferAHostPage" />,
    },
    {
      path: '/inbox/normal-trips/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    // {
    //   path: '/inbox/long-term-trips/:tab',
    //   name: 'InboxLongTermPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <InboxLongTermPage {...props} />,
    //   loadData: InboxLongTermPage.loadData,
    // },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details.',
      name: 'OrderDetailsPage.',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/add-ons',
      name: 'AddOnsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AddOnsPage {...props} transactionRole="customer" />,
      loadData: params => AddOnsPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: AddOnsPage.setInitialValues,
    },
    {
      path: '/order/:id/trip-details',
      name: 'TripDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TripDetailsPage {...props} transactionRole="customer" />,
      loadData: params => TripDetailsPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TripDetailsPage.setInitialValues,
    },
    {
      path: '/order/:id/policies',
      name: 'PoliciesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PoliciesPage {...props} />,
      loadData: params => PoliciesPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: PoliciesPage.setInitialValues,
    },
    {
      path: '/order/:id/edit-trip',
      name: 'EditTripPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EditTripPage {...props} />,
      loadData: params => EditTripPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: EditTripPage.setInitialValues,
    },
    {
      path: '/order/:id/beginner-resources',
      name: 'BeginnerResourcesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BeginnerResourcesPage {...props} />,
      loadData: params => TripDetailsPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TripDetailsPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details.',
      name: 'SaleDetailsPage.',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/account/personal-details',
      name: 'PersonalDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PersonalDetailsPage {...props} />,
      loadData: PersonalDetailsPage.loadData,
    },
    {
      path: '/account/sync-details',
      name: 'SyncCalenderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SyncCalenderPage {...props} />,
      loadData: PersonalDetailsPage.loadData,
    },
    {
      path: '/account/address-details',
      name: 'AddressDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AddressDetailsPage {...props} />,
      loadData: AddressDetailsPage.loadData,
    },
    {
      path: '/account/nric-fin-setting',
      name: 'NRICSettingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NRICSettingPage {...props} />,
      loadData: NRICSettingPage.loadData,
    },
    {
      path: '/account/driving-verification',
      name: 'AccountSettingDriverVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountSettingDriverVerificationPage {...props} />,
      loadData: AccountSettingDriverVerificationPage.loadData,
    },
    {
      path: '/account/payout-pref',
      name: 'PayoutPrefPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PayoutPrefPage {...props} />,
      loadData: PayoutPrefPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/credits',
      name: 'CreditsDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CreditsDetailsPage {...props} />,
      loadData: CreditsDetailsPage.loadData,
    },
    {
      path: '/account/monthly-statements',
      name: 'MonthlyStatementPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MonthlyStatementPage {...props} />,
      loadData: MonthlyStatementPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/account/refundable-deposit',
      name: 'RefundableDepositPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <RefundableDepositPage {...props} />,
      loadData: RefundableDepositPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/rental-agreement',
      name: 'RentalAgreementPage',
      component: props => <RentalAgreementPage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/facebook-data-deletion-instructions',
      name: 'DataDeletionInstruction',
      component: props => <DataDeletionInstruction {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },

    //Thiz izs phor denoratee zhe youser for DRIZALE
    {
      path: '/verify-email-for-generated-user',
      name: 'EmailVerificationPage',
      component: props => <GenerateUserPage {...props} />,
    },
    {
      path: '/go',
      name: 'LandingPageGo',
      component: props => <LandingPageGo {...props} />,
    },
    {
      path: '/byod',
      name: 'LandingPageByod',
      component: props => <LandingPageByod {...props} />,
    },
    {
      path: '/go-host',
      name: 'LandingPageGoHost',
      component: props => <LandingPageGoHost {...props} />,
    },
    {
      path: '/check-eligibility/:tab',
      name: 'CheckEligibilityPage',
      component: props => <CheckEligibilityPage {...props} />,
      auth: true,
    },
    {
      path: '/request-check-eligibility',
      name: 'RequestCheckEligibility',
      component: () => <NamedRedirect name="CheckEligibilityPage" params={{ tab: 'check' }} />,
    },
    {
      path: '/easy-access',
      name: 'NextLevelPage',
      component: props => <NextLevelPage {...props} />,
      auth: true,
      loadData: NextLevelPage.loadData,
    },
    {
      path: '/instantbooking',
      name: 'InstantBookingLandingPage',
      component: props => <InstantBookingLandingPage {...props} />,
      loadData: InstantBookingLandingPage.loadData,
    },
    // {
    //   path: '/mastercard',
    //   name: 'MasterCardLandingPage',
    //   component: props => <MasterCardLandingPage {...props} />,
    // },
    {
      path: '/finalise-your-account-guest',
      name: 'FinaliseGuestAccount',
      component: () => <NamedRedirect name="AccountSettingDriverVerificationPage" />,
    },
    {
      path: '/finalise-your-account-host',
      name: 'FinaliseHostAccount',
      component: () => <NamedRedirect name="StripePayoutPage" />,
    },
    // {
    //   path: '/virus-free-program',
    //   name: 'CovidLandingPage',
    //   component: props => <CovidLandingPage {...props} />,
    //   loadData: CovidLandingPage.loadData,
    // },
    {
      path: '/virus-free-program-checkout',
      name: 'CovidCheckoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="CovidCheckoutBasePage" params={{ tab: 'checkout' }} />,
    },
    {
      path: '/virus-free-program-checkout/:tab',
      name: 'CovidCheckoutBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CovidCheckoutPage {...props} />,
      loadData: CovidCheckoutPage.loadData,
    },
    {
      path: '/signup-landing',
      name: 'SignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/scoot-drivelah-signup',
      name: 'ScootSignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/wework-drivelah-promo',
      name: 'ScootSignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/sdc-drivelah-promo',
      name: 'ScootSignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/motorist-drivelah-promo',
      name: 'ScootSignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/multico-drivelah-promo',
      name: 'ScootSignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/hmlet-drivelah-promo',
      name: 'ScootSignUpPromoLandingPage',
      component: props => <SignUpPromoLandingPage {...props} />,
      loadData: SignUpPromoLandingPage.loadData,
    },
    {
      path: '/host-signup-landing-page',
      name: 'ScootHostPromoLandingPage',
      component: props => <HostPromoLandingPage {...props} />,
      loadData: HostPromoLandingPage.loadData,
    },
    {
      path: '/drivelah-motorist-promo',
      name: 'HostPromoPartnersLandingPage',
      component: props => <HostPromoLandingPartners {...props} />,
      loadData: HostPromoLandingPartners.loadData,
    },
    {
      path: '/drive-lah-grab-host-your-car',
      name: 'GrabHostYourCarLandingPage',
      component: props => <GrabHostYourCarLandingPage {...props} />,
    },
    {
      path: '/drive-lah-grab-rent-a-car',
      name: 'GrabRentACarLandingPage',
      component: props => <GrabRentACarLandingPage {...props} />,
      loadData: GrabRentACarLandingPage.loadData,
    },
    {
      path: '/vaccination-program',
      name: 'VaccineCredits',
      component: props => <VaccineCredits {...props} />,
      auth: true,
      authPage: 'LoginPage',
      loadData: VaccineCredits.loadData,
    },
    {
      path: '/become-a-host-with-drive-mate-1',
      name: 'PartnerBrandingPage',
      component: props => <PartnerBrandingPage {...props} />,
      loadData: PartnerBrandingPage.loadData,
    },
    {
      path: '/become-a-host-with-drive-mate-2',
      name: 'PartnerBrandingPageVersion2',
      component: props => <PartnerBrandingPageVersion2 {...props} />,
      loadData: PartnerBrandingPageVersion2.loadData,
    },
    {
      path: '/become-a-host-with-drive-mate-3',
      name: 'PartnerBrandingPageVersion3',
      component: props => <PartnerBrandingPageVersion3 {...props} />,
      loadData: PartnerBrandingPageVersion3.loadData,
    },
    // {
    //   path: '/drive-together',
    //   name: 'DonationPage',
    //   component: props => <DonationPage {...props} />,
    // },

    // {
    //   path: '/grab-voucher-promo',
    //   name: 'GrabVoucherPromoLandingPage',
    //   component: props => <GrabVoucherPromoLandingPage {...props} />,
    //   loadData: SignUpPromoLandingPage.loadData,
    // },
    // {
    //   path: '/ambassador-program',
    //   name: 'AmbassadorProgram',
    //   component: props => <AmbassadorProgram {...props} />,
    // },
    {
      path: '/careers',
      name: 'Career',
      component: props => <Career {...props} />,
    },
    {
      path: '/car-rental/:cat/:subcat',
      name: 'SeoLocationLandingPage',
      component: props => <SeoLocationLandingPage {...props} tab="listings" />,
      loadData: SeoLocationLandingPage.loadData,
    },
    // {
    //   path: '/car-rental/car-rental-by-segment',
    //   name: 'SeoSegmentRentalLandingPage',
    //   component: props => <SeoSegmentRentalLandingPage {...props} tab="listings" />,
    //   loadData: SeoLocationLandingPage.loadData,
    // },
  ];
};

export default routeConfiguration;

/**
 * This method will check the current path and return the route name based on that.
 *
 * Note: Right now we are using only for flex-plus ui, if you need to change return value, change code accordingly
 * @param {ReactDomHistory} history
 * @returns {string} [DEFAULT='LandingPage']
 */
export const getRouteName = (history) => {
  if (history && history.location &&
    history.location.pathname &&
    history.location.pathname.includes('/subscription')) {
    return 'LandingPageFlex';
  } else {
    return `LandingPage`;
  }
};
