import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';

import { ThemedTopbarContainer } from '../../containers';
import bannerImage from '../../assets/landingPageNew/banner-bg.jpg';

import { pushGTMBrowseEvent, pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { apiBaseUrl } from '../../util/api';
import Cookies from 'js-cookie';
import {
  EVENT_BROWSE_HOMEPAGE,
  EVENT_BROWSE_LOGGEDIN,
  EVENT_SIGNUP_SOCIAL_FACEBOOK,
  EVENT_SIGNUP_SOCIAL_GOOGLE,
  EVENT_SIGNUP_SUBMIT_FORM,
  SIGNUP_SUBMIT_BUTTON_ID,
  SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import BannerSection from './BannerSection';
import ChannelsListings from './ChannelListings';
import RentCarSection from './RentCarSection';
import RentalCounter from './RentalCounter';
import FindCar from './FindCar';
import LoveDrivemate from './LoveDrivemate';
import AboutDrivelah from './AboutDrivelah';
import ListYourCar from './ListYourCar';
import GetApp from './GetApp';
import css from './NewGuestLandingPage.css';
import classNames from 'classnames';
import ThemedFooter from '../../components/ThemedFooter/ThemedFooter';
import { authenticationInProgress, login, signup } from '../../ducks/Auth.duck';
import { LoginFormThemed, SignupFormThemed } from '../../forms';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_SUBMIT_EMAIL_ID } from '../../util/conversions/conversionsConstants';
import { get } from 'lodash';
import { parse } from '../../util/urlHelpers';
import { ensureCurrentUser } from '../../util/data';
import moment from 'moment';
import { fetchFeaturedGuestCar } from '../SearchPage/SearchPage.duck';
import { checkIfReferralCode, checkSignUpCode } from '../../util/referralProgram';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { gSend, GTAG_ACTIONS, GTAG_METHODS } from '../../util/gtag';
import CitiesModal, {
  CitiesModalContentComponent,
} from '../../containers/LandingPageFlex/CitiesModal';
import { cities } from '../../marketplace-custom-config';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const getSearchDateParameters = () => {
  let updatedStartDate, updatedEndDate, updatedStartTime, updatedEndTime;
  updatedStartDate = moment().add(1, 'day').set({ hour: 9, minute: 0 });
  updatedEndDate = moment().add(4, 'day').set({ hour: 9, minute: 0 });
  updatedStartTime = moment().set({ hour: 9, minute: 0 });
  updatedEndTime = moment().set({ hour: 9, minute: 0 });
  return {
    dates: `${updatedStartDate.format('YYYY-MM-DD').trim()},${updatedEndDate.format('YYYY-MM-DD').trim()}`,
    hours: `${updatedStartTime.format('HH:mm').trim()},${updatedEndTime.format('HH:mm').trim()}`,
  }
};

const getBoundsByCityKey = (city) => {
  let result = '';
  const _city = cities.find((item) => {
    if (item.key === city) {
      // result = new LatLngBounds(
      //   new LatLng(item.bounds.northeast.latitude, item.bounds.northeast.longitude),
      //   new LatLng(item.bounds.southwest.latitude, item.bounds.southwest.longitude),
      // );
      result = item.bounds.string;
      return item;
    }
  });
  return result;
};

const getCityByCityKey = (city) => {
  let result = '';
  const _city = cities.find((item) => {
    if (item.key === city) {
      result = item.label;
      return item;
    }
  });
  return result;
};

export const NewGuestLandingPageComponent = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [authInfo, setAuthInfo] = useState(Cookies.get('st-authinfo') ? JSON.parse(Cookies.get('st-authinfo').replace('j:', '')) : null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    viewport,
    currentUser,
    submitSignup,
    authInProgress,
    tab,
    location,
    submitLogin,
    isAuthenticated
  } = props;

  useEffect(() => {
    if (currentUser && currentUser.id && currentUser.id.uuid) {
      setIsUserLoggedIn(true);
    }
  }, [currentUser]);

  const locationFrom = location.state && location.state.from ? location.state.from : null;
  const authinfoFrom =
    authInfo && authInfo.from ? authInfo.from : null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'GuestLandingPageNew.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'GuestLandingPageNew.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${bannerImage}`;

  const [videoModalOpen, onVideoModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(false);
  const [codeType, setCodeType] = useState('')
  const [codeAuthor, setCodeAuthor] = useState('')
  const [loading, setLoading] = useState(false);
  const [carryEmail, setCarryEmail] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const signUpCode = parse(location.search).code;

  const { email: signUpEmail = null, password = null } = get(location, 'state', {}) || {};

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const initEmail = parse(location.search).email;
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
  const history = useHistory();
  const onPushGTMEvent = (event, buttonId) => {
    pushGTMBrowseEvent({
      props,
      event,
      buttonId,
    });
  };

  useEffect(() => {
    if (signUpCode) {
      setLoading(true);
      //Set Code based on the API response from server.
      checkSignUpCode(signUpCode).then(res => {
        if (res.result.valid) {
          localStorage.setItem('code', signUpCode);
          setSignUpCodeAmount(res.result.credits);
          setCodeType(res && res.result && res.result.codeType)
          setCodeAuthor(res && res.result && res.result.details)
        }
        setLoading(false);
      }).catch((error) => {
        console.log('Error:', error);
        setLoading(false);
      });

    }
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
    const from = location.state && location.state.from ? location.state.from : null;
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
      if (window && signUpCode && checkIfReferralCode(signUpCode)) {
        window.alert('You can only use the referral link when you are not logged in');
      }
      return <NamedRedirect name="LandingPage" />;
    }
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }


  const handleSubmit = ({ email, password }) => {
    setIsSignupModalOpen(true);
    setCarryEmail(email);
  };

  const closeModalRedirectToHome = () => {
    setIsSignupModalOpen(false);
    setTimeout(() => {
      history.push('/');
    }, 2000)
  }

  const handleSubmitSignup = values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    submitSignup(params);
    localStorage.setItem('step2', true);
    localStorage.setItem('code', signUpCode);
    localStorage.setItem('codeType', codeType);
    const properties = createRawPropertiesForGTM({
      props: props,
      button: {
        buttonId: SIGNUP_SUBMIT_BUTTON_ID,
      },
    });
    pushGTMSignupForm(properties, EVENT_SIGNUP_SUBMIT_FORM);
    closeModalRedirectToHome()
  };

  const handleSubmitLogin = values => {
    submitLogin(values).then(res => {
      gSend(GTAG_ACTIONS.ACTION_LOGIN, { method: GTAG_METHODS.WEB_LOGIN });
      pushGTMBrowseEvent({
        props: props,
        event: EVENT_BROWSE_LOGGEDIN,
      });
      closeModalRedirectToHome();
    });
  };

  const handleLoginRedirect = () => {
    // setIsLogin(true); // show login modal
    history.push('/login'); // redirect to login page
  }

  const authWithFacebook = (action, method) => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    const properties = createRawPropertiesForGTM({
      props: this.props,
      button: {
        buttonId: SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
      },
    });
    pushGTMSignupForm(properties, EVENT_SIGNUP_SOCIAL_FACEBOOK);
    gSend(action, { method });
  };

  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithGoogle = (action, method) => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    const properties = createRawPropertiesForGTM({
      props: this.props,
      button: {
        buttonId: SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
      },
    });
    pushGTMSignupForm(properties, EVENT_SIGNUP_SOCIAL_GOOGLE);
    gSend(action, { method });
  };

  const doFindCardsByCity = (city) => {
    let dates = getSearchDateParameters();
    const routes = routeConfiguration();
    let query = {};
    if (city) {
      query = {
        pub_listingTimezone: city,
        address: `${getCityByCityKey(city)}`,
        bounds: `${getBoundsByCityKey(city)}`,
        ...dates,
      };
    }
    if (categories && categories.length) {
      // query.pub_category = categories.map((item) => { return item.key; }).join(','); // for now, we no need to add categories filter as per Bhavani
      setCategories([]); // reset categories
    }
    const url = createResourceLocatorString(
      'SearchPage',
      routes,
      {},
      { ...query },
    );
    history.push(url);
  };

  const onCitySelect = (city) => {
    setIsModalOpen(!isModalOpen);
    // Redirect to search
    doFindCardsByCity(city);
  };

  const doModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const doSelect = (categories) => {
    setCategories(categories);
    doModal()
  };

  const isFromCampaign = !!parse(location.search).campaign;
  return (
    <Page
      className={classNames(css.root, css.newTheme)}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: bannerImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${bannerImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [bannerImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <ThemedTopbarContainer
            notApplySearch={true}
            currentPage={'BLandingPage'}
            openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.contentWrapper}>
          <div>
            <BannerSection
              intl={intl}
              onSubmit={handleSubmit}
              openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)}
              signUpCode={signUpCode}
              signUpCodeAmount={signUpCodeAmount}
              codeType={codeType}
              loading={loading}
              codeAuthor={codeAuthor}
            />
            <ChannelsListings />
            <RentCarSection
              intl={intl}
              openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)}
              doModal={() => doModal()}
            />
            <CitiesModal
              isModalOpen={isModalOpen}
              doIsModalOpen={doModal}
              onCitySelect={onCitySelect}
              cityClassContainer={css.cityModalClassContainer}
              cityClassTitle={css.cityModalClassTitle}
              cityClassSubtitle={css.cityModalClassSubtitle}
              cityClassImages={css.cityClassImages}
              title={
                <FormattedMessage id="NewGuestLandingPage.cityModalTitle" />
              }
              subTitle={
                <FormattedMessage id="NewGuestLandingPage.cityModalSubTitle" />
              } />
            <RentalCounter
              onManageDisableScrolling={onManageDisableScrolling}
              onVideoModalOpen={onVideoModalOpen}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
              openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)}
            />
            <div className={css.cityContainer}>
              <CitiesModalContentComponent
                onCitySelect={doFindCardsByCity}
                cityClassContainer={css.cityClassContainer}
                cityClassTitle={css.cityClassTitle}
                cityClassSubtitle={css.cityClassSubtitle}
                title={
                  <FormattedMessage id="NewGuestLandingPage.cityTitle" />
                }
                subTitle={
                  <FormattedMessage id="NewGuestLandingPage.citySubTitle" />
                }
              />
            </div>
            <FindCar doSelect={doSelect} />
            {/* <DiscoverSection openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)} /> */}
            {/* <LoveDrivelah /> */}
            <LoveDrivemate />
            <GetApp intl={intl} onSubmit={handleSubmit} />
            <AboutDrivelah />
            {/* <FeaturedOn /> */}
            <ListYourCar openSignUpModal={() => setIsSignupModalOpen(!isSignupModalOpen)} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <ThemedFooter />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <Modal
        id="insuranceLandingModal"
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
        containerClassName={css.modalContainer}
        onManageDisableScrolling={onManageDisableScrolling}
        closeTextClassName={css.closeButtonText}
        scrollLayerClassName={css.scrollLayerClassName}
        closeIconClassName={css.closeButtonIcon}
        closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
        disableCloseBtn={isUserLoggedIn}
      >
        {
          isUserLoggedIn ?
            <>
              <div className={css.modalTitle}>
                You are already logged in
              </div>
              <div>
                <p>
                  This offer is not available to logged in users. Press the button below to continue to home page.
                </p>
              </div>
              <div className={css.modalFooter}>
                <PrimaryButton type="button" onClick={() => closeModalRedirectToHome()} style={{ textTransform: 'none' }}>
                  Go to home page
                </PrimaryButton>
              </div>
            </>
            :
            <>
              <div className={css.modalTitle}>
                {!isLogin ? (
                  <h2>
                    Sign up on <span>Drive mate</span>
                  </h2>
                ) : (
                  <h2>
                    Log in on <span>Drive mate</span>
                  </h2>
                )}
              </div>
              {
                isLogin ? (
                  <LoginFormThemed
                    className={css.form}
                    onSubmit={handleSubmitLogin}
                    inProgress={authInProgress}
                  />
                ) : (
                  <SignupFormThemed
                    className={css.form}
                    onSubmit={data => {
                      handleSubmitSignup({
                        ...data,
                        codeType: codeType,
                        isFromCampaign: isFromCampaign
                      });
                      const properties = createRawPropertiesForGTM({
                        props: props,
                      });
                      createConversionEvents(properties, EVENT_CONVERSION_SUBMIT_EMAIL_ID, 'click');
                    }}
                    google={() => authWithGoogle(GTAG_ACTIONS.ACTION_SIGNUP, GTAG_METHODS.GOOGLE_SIGNUP)}
                    facebook={() => authWithFacebook(GTAG_ACTIONS.ACTION_SIGNUP, GTAG_METHODS.FACEBOOK_SIGNUP)}
                    inProgress={authInProgress}
                    initEmail={initEmail || signUpEmail}
                    signUpCode={signUpCode}
                    codeType={codeType}
                    submitButtonId={SIGNUP_SUBMIT_BUTTON_ID}
                    initialValues={{ email: carryEmail || signUpEmail, password }}
                  />
                )
              }
              <div className={css.modalFooter}>
                {isLogin ? (
                  <p>
                    New to Drive mate?{' '}
                    <button type="button" onClick={() => setIsLogin(false)}>
                      Sign up
                    </button>
                  </p>
                ) : (
                  <p>
                    Already have an account?{' '}
                    <button type="button" onClick={() => handleLoginRedirect()}>
                      Log in
                    </button>
                  </p>
                )}
              </div>
            </>
        }
      </Modal >
    </Page >
  );
};

const { bool } = PropTypes;

NewGuestLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;

  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NewGuestLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NewGuestLandingPageComponent);

NewGuestLandingPage.loadData = () => {
  return fetchFeaturedGuestCar();
};

NewGuestLandingPage.displayName = 'NewGuestLandingPage';

export default NewGuestLandingPage;
