import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { SIGNUP_UPDATE_SUBMIT_BUTTON_ID } from '../../util/gtm/gtmConstants';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import leftImage from '../../assets/step_2.jpg';

import { SignupFormStep2 } from '../../forms';
import css from './ConfirmSignUpPage.css';
import { authenticationInProgress, update } from '../../ducks/Auth.duck';

export const ConfirmSignUpPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    currentUser,
    authInProgress,
    submitUpdate,
    confirmError,
  } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const [referralCode, setReferralCode] = useState(null);
  const [codeType, setCodeType] = useState('');

  useEffect(() => {
    const code = window.localStorage.getItem('code');
    const codeType = window.localStorage.getItem('codeType');

    if (code) {
      setReferralCode(code);
      setCodeType(codeType);
    }
  }, []);

  console.log("Code and code type used", referralCode, codeType)

  const handleSubmitUpdate = values => {
    const { day, month, year, ...rest } = values;

    submitUpdate({ ...rest });
    localStorage.setItem('step2', false);
  };

  const confirmErrorMessage = confirmError ? <div className={css.error}>{confirmError}</div> : null;
  const getStep2Flag = localStorage.getItem('step2');
  if (currentUser && getStep2Flag === 'false') {
    return <Redirect to={'/'} />;
  }

  const initialValues = {
    code: referralCode ? referralCode : codeType === 'signUpCode' || codeType === 'referralCode' ? referralCode : '',
    codeType: codeType,
  };

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType='website'
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn rootClassName={css.rootLayout}>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'ConfirmSignUpPage'} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.root}>
            <div className={css.authPageContent}>
              <div className={css.leftBannerImage}>
                <img src={leftImage} alt='' />
              </div>
              <div className={css.rightContent}>
                <div className={css.authPageTitle}>Tell us a bit more about yourself</div>
                {confirmErrorMessage}
                <SignupFormStep2
                  initialValues={initialValues}
                  onSubmit={data => handleSubmitUpdate({ ...data })}
                  inProgress={authInProgress}
                  submitButtonId={SIGNUP_UPDATE_SUBMIT_BUTTON_ID}
                />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>{/*<Footer />*/}</LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

ConfirmSignUpPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated, confirmError } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
    authInProgress: authenticationInProgress(state),
    confirmError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitUpdate: params => dispatch(update(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ConfirmSignUpPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ConfirmSignUpPageComponent);

export default ConfirmSignUpPage;
